import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	.button {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		font-size: 1em;
		text-decoration: none;
		cursor: pointer;

		border-radius: 0.5em;
		border: none;

		&.disabled {
			background-color: #909090 !important;
		}
	}

	.button-shadow {
		position: absolute;
		width: 80%;
		height: 40%;
		left: 10%;
		top: 70%;
		z-index: 1;
		opacity: 1;
		filter: blur(0.5em);
		display: none;
	}

	a {
		text-decoration: none;
	}

	span {
		font-size: 1em;
		text-decoration: none;
		cursor: pointer;
	}

	.tooltip-content-mobile {
		font-size: 0.8em;
		color: #666666;
		text-align: center;
		display: none;
	}

	.button-icon-before,
	.button-icon-after {
		.arrow {
			width: 80px;
		}
	}

	.button-icon-before {
		margin-right: 0.5em;
	}

	.button-icon-after {
		margin-left: 0.5em;
	}

	/* Layouts */

	&.layout-default {
		.button {
			padding: 0.7em 1.5em;
		}
	}

	&.layout-wide {
		.button {
			padding: 0.7em 2.5em;
		}
	}

	&.layout-block {
		width: 100%;

		.button {
			padding: 0.7em 1em;
			width: 100%;
		}
	}

	/* Sizes */

	&.size-small {
		.button {
			font-size: 0.75em;
		}
	}

	&.size-medium {
		.button {
			font-size: 0.9em;
		}
	}

	&.size-large {
		.button {
			font-size: 1.15em;
		}
	}

	/* Styles */

	&.style-secondary {
		&.variant-outline {
			.button {
				background-color: transparent;
				color: #ba9449;
				border: 0.01em solid #cfb799;
				box-shadow: none;
			}

			&:hover {
				.button {
					background-color: #cfb799;
					color: white;
				}
			}
		}
	}

	&.style-gold {
		.button {
			.button-icon-after {
				transition: all 0.3s ease-in-out;
			}
		}

		&.variant-primary {
			.button {
				background-color: #ba9449;
				color: white;

				&:hover {
					background-color: #cfb799;
				}
			}
		}

		&.variant-outline {
			.button {
				background-color: transparent;
				padding: 0;

				.button-content {
					color: #ba9449;
					text-transform: uppercase;
					letter-spacing: 0.2em;
				}

				&:hover {
					.button-content {
						color: #cfb799;
					}
				}
			}
		}

		&.variant-underline {
			.button {
				background-color: transparent;
				padding: 0;
				color: #ba9449;

				&:hover {
					.button-content {
						font-weight: 700;
						text-decoration: underline;
					}
				}
			}
		}
	}

	&.style-green {
		&.variant-primary {
			.button {
				background-color: #819367;
				color: #ffffff;

				&:hover {
					background-color: #9eae86;
				}
			}
		}
	}

	&.style-none {
		.button {
			background-color: transparent;
			color: white;
			box-shadow: none;
			padding: 0;

			.button-icon-after,
			.button-icon-before {
				margin: 0;
			}
		}
	}

	&.style-white {
		.button {
			background-color: ${vars.colorWhite};
			color: ${vars.colorGold};

			.button-content {
				font-size: 1.185em;
				font-weight: ${vars.fontRegular};
			}
		}

		&.disabled {
			.button {
				background-color: ${vars.colorWhite} !important;
				color: ${vars.colorGold} !important;
				opacity: 0.6;
			}
		}

		&.variant-outline {
			.button {
				border-radius: 5px;
				border: 1px solid ${vars.colorWhite};
				background-color: transparent;
				padding: 0.5em 3em;

				transition: background-color 0.2s ease-in-out;

				.button-content {
					color: #ffffff;
					font-size: 1em;
					transition: color 0.2s ease-in-out;
				}

				&:hover {
					background-color: ${vars.colorWhite};

					.button-content {
						color: ${vars.colorGold};
					}
				}
			}
		}
	}

	/* Modificators */

	&.disabled {
		.button {
			background-color: #666666 !important;
			color: white !important;
		}
	}

	&.shadow {
		.button-shadow {
			display: block;
		}
	}

	@media all and (max-width: ${vars.tabletS}) {
		.tooltip-content-mobile {
			display: block;
			margin-top: 0.3em;
		}

		.__react_component_tooltip {
			display: none !important;
		}
	}
`;
