import { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import SpinnerNew, { Colors, Positions, Sizes } from 'components/layout/SpinnerNew';
import Tooltip from 'components/layout/Tooltip';

import { ButtonElements, ButtonIconPositions, ButtonIconTypes, Props } from './index';
import StyledComponent from './styles';

const LayoutButton: FunctionComponent<Props> = ({
    children,
    href,
    onClick,
    type,
    element,
    className,
    icon,
    disabled,
    size,
    style,
    styles,
    variant,
    layout,
    loading,
    confirm,
    buttonProps,
    shadow,
    tooltip,
}) => {
    const router = useRouter();

    const defaultConfirmProps = {
        message: 'Potwierdź swój wybór',
    };

    const onCheckClick = () => {
        if (disabled) {
            return;
        }

        if (confirm?.enabled) {
            const confirmConfig = { ...defaultConfirmProps, ...confirm };

            if (window.confirm(confirmConfig.message)) {
                return onClick && onClick();
            } else {
                return null;
            }
        }

        return onClick && onClick();
    };

    const getIcon = () => {
        if(!icon) return null;

        if(icon.type === ButtonIconTypes.Element) {
            return icon.value || null;
        }

        if(icon.type === ButtonIconTypes.Image) {
            return (
                <img
                    className={`button-icon-image ${className}`}
                    src={icon.value}
                    loading="lazy"
                    alt="Opis obrazka"
                    width={icon.width || '20px'}
                    height={icon.height || '20px'}
                />
            );
        }

        return null;
    };

    const getContent = () => {
        return loading
            ? (
                <SpinnerNew
                    color={Colors.White}
                    size={Sizes.Small}
                    position={Positions.Absolute}
                />
            )
            : (
                <>
                    {icon && icon.position === ButtonIconPositions.Before && <div className="button-icon-before">{getIcon()}</div>}
                    <span
                        className="button-content"
                        dangerouslySetInnerHTML={{ __html: children }}
                    />
                    {icon && icon.position === ButtonIconPositions.After && <div className="button-icon-after">{getIcon()}</div>}
                </>
            );
    };

    const getElement = () => {
        let button = (
            <button
                type={type}
                onClick={onCheckClick}
                disabled={disabled}
                className="button"
                {...buttonProps}
            >
                {getContent()}
            </button>
        );

        if (tooltip && tooltip.visible) {
            button = (
                <Tooltip
                    name={tooltip.key}
                    text={tooltip.text}
                >
                    {button}
                    <div className="tooltip-content-mobile">
                        {tooltip.text}
                    </div>
                </Tooltip>
            );
        }

        switch (element) {
            case ButtonElements.Anchor:
                if(href?.includes('#')) {
                    return (
                        <a
                            onClick={(e) => onClick ? onClick(e) : router.push(href) }
                            className="button"
                            {...buttonProps}
                        >
                            {getContent()}
                        </a>
                    );
                }

                return (
                    <a
                        href={href}
                        onClick={(e) => onClick ? onClick(e) : null }
                        className="button"
                        {...buttonProps}
                    >
                        {getContent()}
                    </a>
                );
            default:
                return button;
        }
    };

    return (
        <StyledComponent
            className={classnames(
                'layout-button',
                className,
                `element-${element}`,
                `size-${size}`,
                `style-${style}`,
                `variant-${variant}`,
                `layout-${layout}`,
                {
                    disabled,
                    shadow: shadow?.enabled,
                }
            )}
            style={styles}
        >
            {getElement()}
            {shadow && shadow?.enabled && (
                <div
                    className="button-shadow"
                    style={shadow.style || {}}
                />
            )}
        </StyledComponent>
    );
};

export default LayoutButton;
